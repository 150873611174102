<template>
    <section style="position: relative" class="crypto-section">
        <div class="section-crypto">
            <div class="section-crypto-container">
                <div class="section-crypto-container_desc">
                    <h3 v-html="$t('exchange.crypto.title')" class="section-crypto-container_desc-title" />
                    <div class="section-crypto-container_desc-technologies">
                        <div 
                            v-for="(element, index) in technologies"
                            :key="index"
                            class="section-crypto-container_desc_item"
                        >
                            <img class="section-crypto-container_icon" :src="require(`@/assets/img/technology/${element.icon}.svg`)">
                            <dl>
                                <dt class="section-crypto-container_tech">{{$t(element.name)}}</dt>
                                <dd class="section-crypto-container_def">{{$t(element.description)}}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-crypto-container_image">
                <img class="section-crypto-container_image--img"
                    :src="require(`@/assets/img/scenes/exchange-crypto-money.svg`)" alt="">
            </div>
        </div>
        <svg-icon name="borderTopWhite" />
    </section>
</template>

<script>
export default {
    data: () => ({
        technologies: [
            {
                icon: 'java',
                name: 'exchange.crypto.techname-1',
                description: 'exchange.crypto.techdesc-1'
            },
            {
                icon: 'gradle',
                name: 'exchange.crypto.techname-2',
                description: 'exchange.crypto.techdesc-2'
            },
            {
                icon: 'postgresql',
                name: 'exchange.crypto.techname-3',
                description: 'exchange.crypto.techdesc-3'
            },
            {
                icon: 'vue',
                name: 'exchange.crypto.techname-4',
                description: 'exchange.crypto.techdesc-4'
            },
        ]
    })
}
</script>

<style lang="sass" scoped>
@import '@/pages/exchange/sections/cryptocurrency.sass'

.svg-icon--borderTopWhite
    height: 39px
    width: 100%
    position: absolute
    transform: rotate(180deg)
    top: 100%
    left: 0
    z-index: 9
    margin-top: -1px
</style>