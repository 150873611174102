<template>
    <section class="features-section">
        <div class="features-section-head">
                                
            <div class="features-section__top text-c">
                <div v-if="!!lightsOn" class="features-section__lights" style="color: white">
                    <div class="features-section__lights-circkle">
                        <svg-icon name="amazing-lines-3"></svg-icon>
                    </div>
                </div>
                <div class="features-container">
                            <h3 class="features-section__list-title">{{$t(title)}}</h3>
                            <ul class="features-section__list-container">
                                <li v-for="(element, index) in itemsCount"
                                    :key="index"
                                    class="features-section__list-container_el"
                                >
                                <svg-icon name="checkmark-2"/>
                                <div v-html="$t(`${localeValues}${index}`)" class="features-section__list-container_el_text" />
                                </li>
                            </ul>
                </div>
            </div>
            <div class="features-section__bottom">
                <v-container fill-height></v-container>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['itemsCount', 'localeValues', 'title', 'lightsOn']
}
</script>

<style lang="sass" scoped>
@import '@/pages/asections/features.sass'

.features-container
    display: flex 
    flex-direction: column 
    align-items: center
    padding: 160px 5% 160px 10%
    @media screen and (max-width: 1200), (min-device-width: 768) and (max-device-width: 1200px)
        padding: 120px 3% 160px 2%
    @media screen and (max-width: 900px), (min-device-width: 320px) and (max-device-width: 768px)
        padding: 80px 1%
</style>