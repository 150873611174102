<template>
    <section class="offer-section">
        <div class="offer-section-desc">
            <div class="offer-section-desc-head">
                {{$t(description.headtext)}}
                <span class="offer-section-desc-head_bl"/>
            </div>
            <h3 v-html="$t(description.maintext)" class="offer-section-desc-main_title"></h3>
            <p v-html="$t(description.bottomtext)" class="offer-section-desc-bottom_subtitle"></p>
        </div>
        <div class="offer-section-main-pics">
            <img class="offer-section-main-pics_speedtest" :src="require(`@/assets/img/scenes/exchange-offer-speedtest.svg`)" alt="" />
            <p class="offer-section-main-pics_text" v-html="$t('gradient.confirm')"  />
            <img class="offer-section-main-pics_network" :src="require(`@/assets/img/scenes/exchange-offer-network.svg`)" alt="" />
        </div>
    </section>
</template>

<script>
export default {
    props: ['description']
}
</script>

<style lang="sass" scoped>
@import '@/pages/exchange/sections/offer.sass'
</style>