<template>
    <div class="exchange-page">
        <section-head title="sectionhead.title-exchange" subtitle="sectionhead.subtitle-exchange"/>
        <section-offer style="margin-top: 100px" :description="options.description"/> 
        <section-features :itemsCount="12" localeValues="exchange.features.item-" title="exchange.features.title"/>
        <section-cryptocurrency style="margin-top: 160px" />  
        <section-support />
        <section-marketing />
        <section-footer paragraph="sectionfooter.paragraph-default" borderType="straight" />
    </div>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import sectionFooter from '@/pages/asections/sectionFooter.vue';
import offer from '@/pages/exchange/sections/offer.vue';
import features from '@/pages/asections/features.vue';
import cryptocurrency from '@/pages/exchange/sections/cryptocurrency.vue';
import support from '@/pages/exchange/sections/support.vue';
import marketing from '@/pages/exchange/sections/marketing.vue';
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
        return {
            title: this.$t('meta_title-exchange'),
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_exchange-description'),
            link: getAlternatesForCurrentLanguage().concat(getCanonicals())
        }
    },
    data:() => ({
        options: {
            description: {
                headtext: 'exchange.desc.headtext',
                maintext: 'exchange.desc.maintext',
                bottomtext: 'exchange.desc.bottomtext'
            }
        }
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-offer': offer,
        'section-features': features,
        'section-cryptocurrency': cryptocurrency,
        'section-support': support,
        'section-marketing': marketing
    }
}
</script>

<style lang="sass" scoped>
@import '@/pages/exchange/exchangePage.sass'
.exchange-page
    color: $color--dark
</style>