import { render, staticRenderFns } from "./offer.vue?vue&type=template&id=5fb19195&scoped=true&"
import script from "./offer.vue?vue&type=script&lang=js&"
export * from "./offer.vue?vue&type=script&lang=js&"
import style0 from "./offer.vue?vue&type=style&index=0&id=5fb19195&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb19195",
  null
  
)

export default component.exports